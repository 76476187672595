<template>
  <full-view :title="$t('order.details')">
    <div class="overflow-y-auto" :style="getViewHeightStyle(56, 0)">
      <order-details
        v-model="order"
        :history="history"
        :support="support"
        :customer="order && ['DISPATCHED', 'DELIVERY', 'NEAR'].includes(order.activeTrackStatus) ? customer : null"
        :driver="order && ['DISPATCHED', 'DELIVERY', 'NEAR'].includes(order.activeTrackStatus) ? driver : null"
        :audiance="audiance"
      />
    </div>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import SectionHeader from "@/common/components/SectionHeader";
import OrderDetails from "@/views/orders/OrderDetails";

export default {
  name: "Order",
  components: { FullView, SectionHeader, OrderDetails },
  props: {
    history: Boolean,
    customer: Boolean,
    driver: Boolean,
    support: Boolean,
    audiance: {
      type: String,
      validator: function(value) {
        return ['Customer', 'Driver', 'Support', 'Manager'].includes(value);
      }
    }
  },
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.order = this.getOrderByUuid(this.getUuid);
    console.log("order:", this.order);
    if (!this.order) {
      this.loadOrderByUuid(this.getUuid).then((d) => {
        this.order = this.getOrderByUuid(this.getUuid);
        console.log("order:", d);
      });
    }
  },
  computed: {
    ...mapGetters("orders", ["getOrderByUuid"]),

    getUuid() {
      return this.$route.params.uuid;
    },
  },
  methods: {
    ...mapActions("orders", ["loadOrderByUuid"]),

    getAddressString(a) {
      if (!a) {
        return "";
      }

      return `${a.title} / ${a.buildingNumber} ${a.street}, ${a.area} (
        ${a.latitude.toLocaleString({ minimumFractionDigits: 5 })} -
        ${a.longitude.toLocaleString({ minimumFractionDigits: 5 })})`;
    },
  },
};
</script>
